<script setup lang="ts">
import type { PropType } from 'vue';

const props = defineProps({
  mints: { type: Object as PropType<any>, required: true },
  pending: { type: Boolean, required: true },
});
const { mints, pending } = toRefs(props);
</script>
<template>
  <div class="w-full">
    <div
      class="grid w-full grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-6 md:grid-cols-3 md:gap-6 xl:grid-cols-4 xl:gap-8"
    >
      <CollectionsMintCard v-for="mint in mints" :key="mint.id" :collection="mint" />
      <template v-if="pending && !mints?.length">
        <CollectionsMintCard v-for="index in 4" :key="index" :collection="undefined" />
      </template>
    </div>
  </div>
</template>
